// Generated by CoffeeScript 1.7.1
(function() {
  var Dplib;

  Dplib = (function() {
    var copy;

    function Dplib() {}

    copy = function(dst, dstOffset, src, srcOffset, n) {
      var destination, source;
      destination = new Uint8Array(dst, dstOffset, n);
      source = new Uint8Array(src, srcOffset, n);
      destination.set(source);
      return dst;
    };

    Dplib.unpc_block = function(pc1, out, num, coefs, active, chanbits, denshift) {
      var a0, a1, a2, a3, a4, a5, a6, a7, b0, b1, b2, b3, b4, b5, b6, b7, chanshift, dd, del, del0, denhalf, i, j, lim, offset, prev, sg, sgn, sum1, top, _i, _j, _k, _l, _m, _n, _o, _p, _ref, _ref1;
      chanshift = 32 - chanbits;
      denhalf = 1 << (denshift - 1);
      out[0] = pc1[0];
      if (active === 0) {
        return copy(out, 0, pc1, 0, num * 4);
      }
      if (active === 31) {
        prev = out[0];
        for (i = _i = 1; _i < num; i = _i += 1) {
          del = pc1[i] + prev;
          prev = (del << chanshift) >> chanshift;
          out[i] = prev;
        }
        return;
      }
      for (i = _j = 1; _j <= active; i = _j += 1) {
        del = pc1[i] + out[i - 1];
        out[i] = (del << chanshift) >> chanshift;
      }
      lim = active + 1;
      if (active === 4) {
        a0 = coefs[0], a1 = coefs[1], a2 = coefs[2], a3 = coefs[3];
        for (j = _k = lim; _k < num; j = _k += 1) {
          top = out[j - lim];
          offset = j - 1;
          b0 = top - out[offset];
          b1 = top - out[offset - 1];
          b2 = top - out[offset - 2];
          b3 = top - out[offset - 3];
          sum1 = (denhalf - a0 * b0 - a1 * b1 - a2 * b2 - a3 * b3) >> denshift;
          del = del0 = pc1[j];
          sg = (-del >>> 31) | (del >> 31);
          del += top + sum1;
          out[j] = (del << chanshift) >> chanshift;
          if (sg > 0) {
            sgn = (-b3 >>> 31) | (b3 >> 31);
            a3 -= sgn;
            del0 -= 1 * ((sgn * b3) >> denshift);
            if (del0 <= 0) {
              continue;
            }
            sgn = (-b2 >>> 31) | (b2 >> 31);
            a2 -= sgn;
            del0 -= 2 * ((sgn * b2) >> denshift);
            if (del0 <= 0) {
              continue;
            }
            sgn = (-b1 >>> 31) | (b1 >> 31);
            a1 -= sgn;
            del0 -= 3 * ((sgn * b1) >> denshift);
            if (del0 <= 0) {
              continue;
            }
            a0 -= (-b0 >>> 31) | (b0 >> 31);
          } else if (sg < 0) {
            sgn = -((-b3 >>> 31) | (b3 >> 31));
            a3 -= sgn;
            del0 -= 1 * ((sgn * b3) >> denshift);
            if (del0 >= 0) {
              continue;
            }
            sgn = -((-b2 >>> 31) | (b2 >> 31));
            a2 -= sgn;
            del0 -= 2 * ((sgn * b2) >> denshift);
            if (del0 >= 0) {
              continue;
            }
            sgn = -((-b1 >>> 31) | (b1 >> 31));
            a1 -= sgn;
            del0 -= 3 * ((sgn * b1) >> denshift);
            if (del0 >= 0) {
              continue;
            }
            a0 += (-b0 >>> 31) | (b0 >> 31);
          }
        }
        coefs[0] = a0;
        coefs[1] = a1;
        coefs[2] = a2;
        coefs[3] = a3;
      } else if (active === 8) {
        a0 = coefs[0], a1 = coefs[1], a2 = coefs[2], a3 = coefs[3], a4 = coefs[4], a5 = coefs[5], a6 = coefs[6], a7 = coefs[7];
        for (j = _l = lim; _l < num; j = _l += 1) {
          top = out[j - lim];
          offset = j - 1;
          b0 = top - out[offset];
          b1 = top - out[offset - 1];
          b2 = top - out[offset - 2];
          b3 = top - out[offset - 3];
          b4 = top - out[offset - 4];
          b5 = top - out[offset - 5];
          b6 = top - out[offset - 6];
          b7 = top - out[offset - 7];
          sum1 = (denhalf - a0 * b0 - a1 * b1 - a2 * b2 - a3 * b3 - a4 * b4 - a5 * b5 - a6 * b6 - a7 * b7) >> denshift;
          del = del0 = pc1[j];
          sg = (-del >>> 31) | (del >> 31);
          del += top + sum1;
          out[j] = (del << chanshift) >> chanshift;
          if (sg > 0) {
            sgn = (-b7 >>> 31) | (b7 >> 31);
            a7 -= sgn;
            del0 -= 1 * ((sgn * b7) >> denshift);
            if (del0 <= 0) {
              continue;
            }
            sgn = (-b6 >>> 31) | (b6 >> 31);
            a6 -= sgn;
            del0 -= 2 * ((sgn * b6) >> denshift);
            if (del0 <= 0) {
              continue;
            }
            sgn = (-b5 >>> 31) | (b5 >> 31);
            a5 -= sgn;
            del0 -= 3 * ((sgn * b5) >> denshift);
            if (del0 <= 0) {
              continue;
            }
            sgn = (-b4 >>> 31) | (b4 >> 31);
            a4 -= sgn;
            del0 -= 4 * ((sgn * b4) >> denshift);
            if (del0 <= 0) {
              continue;
            }
            sgn = (-b3 >>> 31) | (b3 >> 31);
            a3 -= sgn;
            del0 -= 5 * ((sgn * b3) >> denshift);
            if (del0 <= 0) {
              continue;
            }
            sgn = (-b2 >>> 31) | (b2 >> 31);
            a2 -= sgn;
            del0 -= 6 * ((sgn * b2) >> denshift);
            if (del0 <= 0) {
              continue;
            }
            sgn = (-b1 >>> 31) | (b1 >> 31);
            a1 -= sgn;
            del0 -= 7 * ((sgn * b1) >> denshift);
            if (del0 <= 0) {
              continue;
            }
            a0 -= (-b0 >>> 31) | (b0 >> 31);
          } else if (sg < 0) {
            sgn = -((-b7 >>> 31) | (b7 >> 31));
            a7 -= sgn;
            del0 -= 1 * ((sgn * b7) >> denshift);
            if (del0 >= 0) {
              continue;
            }
            sgn = -((-b6 >>> 31) | (b6 >> 31));
            a6 -= sgn;
            del0 -= 2 * ((sgn * b6) >> denshift);
            if (del0 >= 0) {
              continue;
            }
            sgn = -((-b5 >>> 31) | (b5 >> 31));
            a5 -= sgn;
            del0 -= 3 * ((sgn * b5) >> denshift);
            if (del0 >= 0) {
              continue;
            }
            sgn = -((-b4 >>> 31) | (b4 >> 31));
            a4 -= sgn;
            del0 -= 4 * ((sgn * b4) >> denshift);
            if (del0 >= 0) {
              continue;
            }
            sgn = -((-b3 >>> 31) | (b3 >> 31));
            a3 -= sgn;
            del0 -= 5 * ((sgn * b3) >> denshift);
            if (del0 >= 0) {
              continue;
            }
            sgn = -((-b2 >>> 31) | (b2 >> 31));
            a2 -= sgn;
            del0 -= 6 * ((sgn * b2) >> denshift);
            if (del0 >= 0) {
              continue;
            }
            sgn = -((-b1 >>> 31) | (b1 >> 31));
            a1 -= sgn;
            del0 -= 7 * ((sgn * b1) >> denshift);
            if (del0 >= 0) {
              continue;
            }
            a0 += (-b0 >>> 31) | (b0 >> 31);
          }
        }
        coefs[0] = a0;
        coefs[1] = a1;
        coefs[2] = a2;
        coefs[3] = a3;
        coefs[4] = a4;
        coefs[5] = a5;
        coefs[6] = a6;
        coefs[7] = a7;
      } else {
        for (i = _m = lim; _m < num; i = _m += 1) {
          sum1 = 0;
          top = out[i - lim];
          offset = i - 1;
          for (j = _n = 0; _n < active; j = _n += 1) {
            sum1 += coefs[j] * (out[offset - j] - top);
          }
          del = del0 = pc1[i];
          sg = (-del >>> 31) | (del >> 31);
          del += top + ((sum1 + denhalf) >> denshift);
          out[i] = (del << chanshift) >> chanshift;
          if (sg > 0) {
            for (j = _o = _ref = active - 1; _o >= 0; j = _o += -1) {
              dd = top - out[offset - j];
              sgn = (-dd >>> 31) | (dd >> 31);
              coefs[j] -= sgn;
              del0 -= (active - j) * ((sgn * dd) >> denshift);
              if (del0 <= 0) {
                break;
              }
            }
          } else if (sg < 0) {
            for (j = _p = _ref1 = active - 1; _p >= 0; j = _p += -1) {
              dd = top - out[offset - j];
              sgn = (-dd >>> 31) | (dd >> 31);
              coefs[j] += sgn;
              del0 -= (active - j) * ((-sgn * dd) >> denshift);
              if (del0 >= 0) {
                break;
              }
            }
          }
        }
      }
    };

    return Dplib;

  })();

  module.exports = Dplib;

}).call(this);
