// Generated by CoffeeScript 1.7.1
(function() {
  var Matrixlib;

  Matrixlib = (function() {
    function Matrixlib() {}

    Matrixlib.unmix16 = function(u, v, out, stride, samples, mixbits, mixres) {
      var i, l, _i, _j;
      if (mixres === 0) {
        for (i = _i = 0; _i < samples; i = _i += 1) {
          out[i * stride + 0] = u[i];
          out[i * stride + 1] = v[i];
        }
      } else {
        for (i = _j = 0; _j < samples; i = _j += 1) {
          l = u[i] + v[i] - ((mixres * v[i]) >> mixbits);
          out[i * stride + 0] = l;
          out[i * stride + 1] = l - v[i];
        }
      }
    };

    return Matrixlib;

  })();

  module.exports = Matrixlib;

}).call(this);
