// Generated by CoffeeScript 1.7.1
(function() {
  var Aglib;

  Aglib = (function() {
    var BITOFF, KB0, MAX_DATATYPE_BITS_16, MAX_PREFIX_16, MAX_PREFIX_32, MAX_RUN_DEFAULT, MB0, MDENSHIFT, MMULSHIFT, MOFF, N_MAX_MEAN_CLAMP, N_MEAN_CLAMP_VAL, PB0, QB, QBSHIFT, dyn_get_16, dyn_get_32, lead;

    function Aglib() {}

    PB0 = 40;

    MB0 = 10;

    KB0 = 14;

    MAX_RUN_DEFAULT = 255;

    MAX_PREFIX_16 = 9;

    MAX_PREFIX_32 = 9;

    QBSHIFT = 9;

    QB = 1 << QBSHIFT;

    MMULSHIFT = 2;

    MDENSHIFT = QBSHIFT - MMULSHIFT - 1;

    MOFF = 1 << (MDENSHIFT - 2);

    N_MAX_MEAN_CLAMP = 0xFFFF;

    N_MEAN_CLAMP_VAL = 0xFFFF;

    MMULSHIFT = 2;

    BITOFF = 24;

    MAX_DATATYPE_BITS_16 = 16;

    lead = function(input) {
      var curbyte, output;
      output = 0;
      curbyte = 0;
      while (true) {
        curbyte = input >>> 24;
        if (curbyte) {
          break;
        }
        output += 8;
        curbyte = input >>> 16;
        if (curbyte & 0xff) {
          break;
        }
        output += 8;
        curbyte = input >>> 8;
        if (curbyte & 0xff) {
          break;
        }
        output += 8;
        curbyte = input;
        if (curbyte & 0xff) {
          break;
        }
        output += 8;
        return output;
      }
      if (curbyte & 0xf0) {
        curbyte >>>= 4;
      } else {
        output += 4;
      }
      if (curbyte & 0x8) {
        return output;
      }
      if (curbyte & 0x4) {
        return output + 1;
      }
      if (curbyte & 0x2) {
        return output + 2;
      }
      if (curbyte & 0x1) {
        return output + 3;
      }
      return output + 4;
    };

    dyn_get_16 = function(data, m, k) {
      var bitsInPrefix, offs, result, stream, v;
      offs = data.bitPosition;
      stream = data.peek(32 - offs) << offs;
      bitsInPrefix = lead(~stream);
      if (bitsInPrefix >= MAX_PREFIX_16) {
        data.advance(MAX_PREFIX_16 + MAX_DATATYPE_BITS_16);
        stream <<= MAX_PREFIX_16;
        result = stream >>> (32 - MAX_DATATYPE_BITS_16);
      } else {
        data.advance(bitsInPrefix + k);
        stream <<= bitsInPrefix + 1;
        v = stream >>> (32 - k);
        result = bitsInPrefix * m + v - 1;
        if (v < 2) {
          result -= v - 1;
        } else {
          data.advance(1);
        }
      }
      return result;
    };

    dyn_get_32 = function(data, m, k, maxbits) {
      var offs, result, stream, v;
      offs = data.bitPosition;
      stream = data.peek(32 - offs) << offs;
      result = lead(~stream);
      if (result >= MAX_PREFIX_32) {
        data.advance(MAX_PREFIX_32);
        return data.read(maxbits);
      } else {
        data.advance(result + 1);
        if (k !== 1) {
          stream <<= result + 1;
          result *= m;
          v = stream >>> (32 - k);
          data.advance(k - 1);
          if (v > 1) {
            result += v - 1;
            data.advance(1);
          }
        }
      }
      return result;
    };

    Aglib.ag_params = function(m, p, k, f, s, maxrun) {
      return {
        mb: m,
        mb0: m,
        pb: p,
        kb: k,
        wb: (1 << k) - 1,
        qb: QB - p,
        fw: f,
        sw: s,
        maxrun: maxrun
      };
    };

    Aglib.dyn_decomp = function(params, data, pc, samples, maxSize) {
      var c, j, k, kb, m, mb, multiplier, mz, n, ndecode, pb, wb, zmode, _i;
      pb = params.pb, kb = params.kb, wb = params.wb, mb = params.mb0;
      zmode = 0;
      c = 0;
      while (c < samples) {
        m = mb >>> QBSHIFT;
        k = Math.min(31 - lead(m + 3), kb);
        m = (1 << k) - 1;
        n = dyn_get_32(data, m, k, maxSize);
        ndecode = n + zmode;
        multiplier = -(ndecode & 1) | 1;
        pc[c++] = ((ndecode + 1) >>> 1) * multiplier;
        mb = pb * (n + zmode) + mb - ((pb * mb) >> QBSHIFT);
        if (n > N_MAX_MEAN_CLAMP) {
          mb = N_MEAN_CLAMP_VAL;
        }
        zmode = 0;
        if (((mb << MMULSHIFT) < QB) && (c < samples)) {
          zmode = 1;
          k = lead(mb) - BITOFF + ((mb + MOFF) >> MDENSHIFT);
          mz = ((1 << k) - 1) & wb;
          n = dyn_get_16(data, mz, k);
          if (!(c + n <= samples)) {
            return false;
          }
          for (j = _i = 0; _i < n; j = _i += 1) {
            pc[c++] = 0;
          }
          if (n >= 65535) {
            zmode = 0;
          }
          mb = 0;
        }
      }
      return true;
    };

    return Aglib;

  })();

  module.exports = Aglib;

}).call(this);
